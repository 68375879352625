import { UserData } from '@/app/User/Models/UserData';

export default class User {
    public readonly token: string;
    public readonly id: number;

    constructor(userData: UserData) {
        this.token = userData.token;
        this.id = parseInt(userData.user);
    }
}
