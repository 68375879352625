import axios, { AxiosInstance } from 'axios';
import BaseService from './BaseService';
import TemplateService from '@/app/EmailTemplates/Services/TemplateService';
import TemplatesLogger from '@/app/EmailTemplates/Services/TemplatesLogger';

export default class ServicesInitializer {
    private readonly templatesClient: AxiosInstance = null;
    private readonly client: AxiosInstance = null;

    constructor() {
        this.templatesClient = this.createTemplatesClient();
        this.client = this.createClient();
    }

    public getServices(instanceToken: string, userToken: string): Record<string, BaseService> {
        return {
            templateService: new TemplateService(this.templatesClient, instanceToken, userToken),
            templatesLogger: new TemplatesLogger(this.client, instanceToken, userToken),
        };
    }

    private createTemplatesClient(): AxiosInstance {
        const baseURL = process.env.VUE_APP_TEMPLATES_API_BASE_URL || 'https://api.templates.solucx.com.br';
        return axios.create({ baseURL });
    }

    private createClient(): AxiosInstance {
        const baseURL = process.env.VUE_APP_API_BASE_URL || 'https://api.solucx.com.br';
        return axios.create({ baseURL });
    }
}
