
import { BButton } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { BButton } })
export default class Button extends Vue {
    @Prop({ required: false, default: null }) public icon: string;
    @Prop({ required: false, default: false }) public disabled: string;
    @Prop({ required: false, default: null }) public size: string;
    @Prop({ required: false, default: 'secondary' }) public variant: string;
    @Prop({ required: false, default: false }) public loading: boolean;

    get hasContent(): boolean {
        return !!this.$slots.default;
    }

    get hasIcon(): string {
        return this.icon;
    }
}
