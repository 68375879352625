import Language from './Language';

const es: Language = {
    BASES_TITLE: 'Plantillas de correo electrónico',
    NEW_TEMPLATE: 'Nueva plantilla',
    BLANK_TEMPLATE: 'Plantilla vacía',
    DEFAULT_TEMPLATE: 'Plantilla por defecto',
    YOUR_TEMPLATES: 'Plantillas creadas',
    NAME: 'Nombre',
    CREATED_AT: 'Creado en',
    UPDATED_AT: 'Fecha de la última edición',
    TEMPLATE_OWNER: 'Propietario',
    LINKED_JOURNEYS: 'Trayectos enlazados',
    ACTIONS: 'Acciones',
    NO_RESULTS: 'Aún no hay plantillas creadas',
    GENERAL_ERROR_MESSAGE: 'Se ha producido un error al recuperar las plantillas',
    SEARCH_TEMPLATES: 'Buscar plantillas',
    DELETE: 'Borrar',
    DELETE_TEMPLATE_POPUP_MESSAGE:
        '¿Está seguro de que desea eliminar la plantilla <strong>{templateName}</strong>? Esta acción es irreversible y no será posible recuperarla.',
    DELETE_TEMPLATE: 'Borrar pantilla',
    CANCEL: 'Cancelar',
    DELETE_TEMPLATE_SUCCESS_MESSAGE: 'Plantilla eliminada correctamente',
    DELETE_TEMPLATE_ERROR_MESSAGE: 'Error al borrar plantilla',
    DELETE_TEMPLATE_IMPEDIMENT_POPUP_MESSAGE:
        'No es posible eliminar la plantilla <strong>{templateName}</strong> porque tiene enlaces a algunos ciclos. Elimina todos los enlaces de esta plantilla e inténtalo de nuevo.',
    CLOSE_NOTICE: 'Cerrar aviso',
    RENAME: 'Renombrar',
    RENAME_TEMPLATE: 'Renombrar plantilla',
    RENAME_TEMPLATE_SUCCESS_MESSAGE: 'Plantilla renombrada con éxito.',
    RENAME_TEMPLATE_ERROR_MESSAGE: 'Se ha producido un error al intentar cambiar el nombre de la plantilla.',
    DUPLICATE: 'Duplicado',
    DUPLICATE_TEMPLATE: 'Plantilla duplicada',
    DUPLICATE_TEMPLATE_SUCCESS_MESSAGE: 'Plantilla duplicada correctamente.',
    DUPLICATE_TEMPLATE_ERROR_MESSAGE: 'Se ha producido un error al intentar duplicar la plantilla.',
    HTML: 'HTML',
    DISPLAY: 'Visualización',
    SAVE: 'Salvar',
    SAVE_TEMPLATE: 'Guardar plantilla',
    SAVE_TEMPLATE_ERROR_TOAST_MESSAGE: 'Se ha producido un error al guardar la plantilla',
    SAVE_TEMPLATE_SUCCESS_TOAST_MESSAGE: 'La plantilla se ha guardado correctamente',
    TEMPLATE_WORKSPACE_ERROR_MESSAGE: 'Se ha producido un error al cargar la plantilla',
    SEND_A_TEST: 'Enviar una prueba',
    INFORMED_A_VALID_EMAIL: 'Introduzca un correo electrónico válido para enviar la plantilla.',
    SEND_THE_EMAIL_TEST: 'Enviar prueba',
    PLACEHOLDER_EXAMPLE_EMAIL: 'ejemplo@ejemplo.com',
    TEST_EMAIL_SUCCESS_MESSAGE: 'El correo electrónico de prueba se ha enviado correctamente.',
    TEST_EMAIL_ERROR_MESSAGE: 'Se ha producido un error al intentar enviar el correo electrónico de prueba',
    TEMPLATE_LINKED_TO_THE_FOLLOWING_JOURNEYS: 'La plantilla <strong>{templateName}</strong> está vinculada a los siguientes trayectos:',
    CLOSE: 'Cerrar',
    THERE_WAS_ERROR_RETRIEVING_THE_LINKED_DAYS: 'Se ha producido un error al recuperar los trayectos enlazados',
    PARAMETERS_LIST: 'Lista de parámetros',
    CREATE_TEMPLATE: 'Nueva plantilla',
    ERROR_WHEN_CREATING_TEMPLATE_MESSAGE: 'Se ha producido un error al intentar crear una plantilla',
};

export default es;
